import styled from "styled-components";

export const PartnersWrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-gap: 70px;
  width: 80%;
  grid-auto-flow: dense;
  list-style: none;
  height: 100%;
  margin: 0 auto;
  padding: 15px 0;

  li {
    min-width: 50px;
    min-height: 20px;
  }
`;
