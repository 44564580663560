import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";

import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";

import { Home, HomeContent } from "../index-page";

import { PartnersWrapper } from "./style";

export const PartnersPageTemplate = ({ title, partners }) => {
  return (
    <>
      <PartnersWrapper>
        {partners.partner.map((partner, index) => (
          <li key={index}>
            <a
              href={partner.link}
              target="_blank"
              rel="noreferrer"
              aria-label="partner-link"
            >
              <img
                src={
                  partner.image?.childImageSharp?.fluid.src ||
                  partner.image?.publicURL
                }
                alt={partner.alt}
              />
            </a>
          </li>
        ))}
      </PartnersWrapper>
    </>
  );
};

PartnersPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  partners: PropTypes.shape({
    partner: PropTypes.array,
  }),
};

const PartnersPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <Home color="white">
        <HomeContent>
          <PartnersPageTemplate
            title={frontmatter.title}
            partners={frontmatter.partners}
          />
        </HomeContent>
      </Home>
    </Layout>
  );
};

PartnersPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PartnersPage;

export const partnersPageQuery = graphql`
  query PartnersPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        partners {
          partner {
            image {
              childImageSharp {
                fluid(maxWidth: 300, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
            alt
            link
          }
        }
      }
    }
  }
`;
